.mobile {

  menu {
    width: 100%;
    padding: 1em;

    h1 {
      margin-top: 1em;
    }
  }

  main {
    left: 0;
    width: 100%;
    padding: 1em;
    overflow-y: auto;
    flex-grow: 1;
  }

  .article {
    padding: 1em;
    overflow-x: hidden;
  }

  .print-link {
    display: none;
  }

  header {
    height: var(--header-height);
    flex-shrink: 0;
    padding-right: 1em;

    .feedback, .support, .connect, .wallpapers {
      display: none;
    }
  }

  .front-page {
    justify-content: flex-start;
    header {
      flex-direction: column;
      height: unset;
    }
    .name, .illustration {
      width: 90vw;
    }
    .illustration {
      height: 30vh;
      margin: 1em;
    }
    h1 {
      font-size: 2em;
      margin: 0.5em;
    }
    h2 {
      margin: 0;
      font-size: 1.2em;
    }
    footer {
      display: none;
    }
    .explore {
      flex-direction: column;
      margin: 0;
    }
    a {
      margin: 0.5em;
    }
  }

  .macro-list {
    padding: 0;

    .card {
      width: 100%;
    }
  }

  .macro-properties {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;

    button.primary {
      display: none;
    }
  }

  .search-bar {

    .search-input {
      flex-grow: 1;
      font-size: 16px; // need minimum 16 px on ios to avoid page zoom
    }
  }

  main.center {
    display: unset; /* dont use flexbox, make side elements appear at bottom */
  }

  /** Place node details on top of the node list page */
  .node-details {
    left: 0;
    width: 100%;
  }

  .quick-list {
    display: none;
  }

  .api-list {
    padding: 1em;

    section {
      margin: 0.5em 0;
    }
  }

  .articles {
    flex-direction: column;
  }

  .card {
    width: 100%;
  }
}

